var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loading'):_c('div',{staticClass:"delivery-list"},[_c('b-card',[_c('div',{staticClass:"p-2 custom-search d-flex align-items-center justify-content-between mb-1"},[_c('h1',{staticClass:"m-0 page-title"},[_vm._v(_vm._s(_vm.$t("delivery.delivery-shifts")))]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.globalPermission.shifts && _vm.globalPermission.shifts.store)?_c('b-button',{staticClass:"btn-add",attrs:{"variant":"primary","to":"/delivery-shifts-add"}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("delivery.add-delivery-shifts"))+" ")],1):_vm._e()],1)]),_c('vue-good-table',{attrs:{"columns":_vm.tableHeader,"rows":_vm.tableBody,"styleClass":"table-custom-style vgt-table striped condensed","pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"search-options":{
        enabled: true,
        skipDiacritics: true,
        placeholder: _vm.$t('search_placeholder'),
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'delivery-area')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("delivery.delivery-area"))+" ")]):(props.column.label === 'shift-time')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("delivery.shift-time"))+" ")]):(props.column.label === 'number-of-delivery')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("delivery.number-of-delivery"))+" ")]):(props.column.label === 'status')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("delivery.status"))+" ")]):(props.column.label === 'Actions')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.action"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'shift-time')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.start_time + " - " + props.row.end_time)+" ")]):(props.column.field === '#')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_vm._v(" "+_vm._s(props.row.index)+" ")]):(props.column.field === 'is_active')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_c('b-form-checkbox',{attrs:{"disabled":!(_vm.globalPermission.shifts && _vm.globalPermission.shifts.update),"switch":"","value":1,"unchecked-value":0},on:{"change":function (e) { return _vm.handleDeliveryVisibility(e, props.row.id); }},model:{value:(props.row.is_active),callback:function ($$v) {_vm.$set(props.row, "is_active", $$v)},expression:"props.row.is_active"}})],1):(
            props.column.field === 'actions' &&
            _vm.globalPermission.shifts &&
            (_vm.globalPermission.shifts.update ||
              _vm.globalPermission.shifts.destroy)
          )?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","text":"Block Level Dropdown Menu","block":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"size":"16","icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'DeliveryShiftsEdit',
                  params: { id: props.row.id },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteShift(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-center flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"empty-table-message"},[_c('p',[_vm._v(_vm._s(_vm.$t("noData")))])])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }