<template>
  <Loading v-if="isLoading" />
  <div v-else class="delivery-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("delivery.delivery-shifts") }}</h1>
        <div class="d-flex align-items-center">
          <b-button
            v-if="globalPermission.shifts && globalPermission.shifts.store"
            variant="primary"
            class="btn-add"
            to="/delivery-shifts-add"
          >
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("delivery.add-delivery-shifts") }}
          </b-button>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span
            v-if="props.column.label === 'delivery-area'"
            class="text-nowrap"
          >
            {{ $t("delivery.delivery-area") }}
          </span>
          <span
            v-else-if="props.column.label === 'shift-time'"
            class="text-nowrap"
          >
            {{ $t("delivery.shift-time") }}
          </span>
          <span
            v-else-if="props.column.label === 'number-of-delivery'"
            class="text-nowrap"
          >
            {{ $t("delivery.number-of-delivery") }}
          </span>

          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("delivery.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span class="text-nowrap" v-if="props.column.field === 'shift-time'">
            {{ props.row.start_time + " - " + props.row.end_time }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === 'is_active'"
          >
            <b-form-checkbox
              :disabled="
                !(globalPermission.shifts && globalPermission.shifts.update)
              "
              switch
              v-model="props.row.is_active"
              @change="(e) => handleDeliveryVisibility(e, props.row.id)"
              :value="1"
              :unchecked-value="0"
            />
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' &&
              globalPermission.shifts &&
              (globalPermission.shifts.update ||
                globalPermission.shifts.destroy)
            "
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  :to="{
                    name: 'DeliveryShiftsEdit',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteShift(props.row.id)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BPagination,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";

export default {
  name: "DeliveryList",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    BButton,
    Loading,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  data() {
    return {
      page_data: null,
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      tableBody: [],
      brandSlug: null,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "delivery-area",
          field: "area",
        },
        {
          label: "shift-time",
          field: "shift-time",
          sortable: false,
        },
        {
          label: "number-of-delivery",

          field: "max_delivery_boys",
          sortable: false,
        },
        {
          label: "status",
          field: "is_active",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getDeliveryList();
  },
  mounted() {
    if (
      this.globalPermission.shifts &&
      !(
        this.globalPermission.shifts.update ||
        this.globalPermission.shifts.destroy
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.field !== "actions"
      );
    }
  },
  methods: {
    async getDeliveryList() {
      try {
        this.tableBody = (await this.$http.get("admin/shifts")).data.data;
        this.tableBody.map((el, index) => {
          (el.index = index + 1), (el.is_active = +el.is_active);
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteShift(shiftId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handleDeleteDelivery(shiftId)
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handleDeleteDelivery(shiftId) {
      this.$http
        .delete(`admin/shifts/${shiftId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getDeliveryList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    async handleDeliveryVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_active: checked,
        };
        const res = await this.$http.post(
          `admin/shifts/${id}?_method=put`,
          data
        );
        if (res.status === 200 || res.status === 201) {
          this.$helpers.makeToast(
            "success",
            res.data.message,
            res.data.message
          );
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    update() {
      this.getDeliveryList();
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
